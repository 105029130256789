import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CustomSnackBarService } from 'app/core/notifications/snackbars/snackbar.service';

export const errorInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const snackBarService = inject(CustomSnackBarService);

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            if (!navigator.onLine) {
                snackBarService._showSnackbarOnFailure('no-internet-connection');
                return throwError(() => new Error('network-error'));
            }
            return throwError(() => error);
        })
    );
};
