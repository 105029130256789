import { Injectable, inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from "@angular/router";
import { AuthService } from "app/core/auth/auth.service";
import { Observable, of, switchMap } from "rxjs";
import { UserService } from "app/core/user/user.service";
import { OrganizationService } from "app/core/administrationSettings/organization.service";
import { GeneralSettingsService } from "app/general-settings.service";
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    /**
     * Constructor
     */
    status: string;
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _userService: UserService,
        private _organizationService: OrganizationService,
        private _generalSettingsService: GeneralSettingsService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        let redirectUrl = state.url === "/sign-out" ? "/" : state.url;
        const clientToken = route.queryParams["clientToken"];
        const wId = route.queryParams["wId"];

        if (clientToken && wId) {
            return this._authService.validateClientApp(clientToken, Number(wId)).pipe(
                switchMap(response => {
                    if (response.valid) {
                        // If valid, navigate to the prep-document component

                        this._organizationService.getBranding().subscribe(
                            response => {
                                if (response) {
                                    const branding = response.APP_BRANDING;
                                    localStorage.setItem('brandingLogo', branding.favicon || '');

                                    const splashLogoElement = document.getElementById('splash-logo');
                                    if (splashLogoElement && branding.favicon) {
                                        splashLogoElement.setAttribute('src', branding.favicon);
                                    }

                                    this._generalSettingsService.setLogo(branding.logo);
                                    this._generalSettingsService.setHeaderBackgroundColor(branding.headerBackgroundColor);
                                    this._generalSettingsService.setHeaderColor(branding.headerColor);
                                    this._generalSettingsService.setLeftPanelColor(branding.leftPanelColor);
                                    this._generalSettingsService.setLeftPanelIconColor(branding.leftPanelIconColor);
                                    this._generalSettingsService.setDialogHeaderColor(branding.dialogsHeaderColor);
                                    this._generalSettingsService.setDialogColor(branding.dialogsColor);
                                    this._generalSettingsService.setPrimaryBackgroundColor(branding.primaryBackgroundColor);
                                    this._generalSettingsService.setSecondaryBackgroundColor(branding.secondaryBackgroundColor);
                                    this._generalSettingsService.setPrimaryColor(branding.primaryColor);
                                    this._generalSettingsService.setSecondaryColor(branding.secondaryColor);
                                    this._generalSettingsService.setOtherBackgroundColor(branding.otherBackgroundColor);
                                    this._generalSettingsService.setOtherColor(branding.otherColor);

                                }
                            }
                        )

                        const redirectURL = "/documents/prepare";
                        this._router.navigate([redirectURL], {
                            queryParams: {
                                wId: btoa(wId),
                                dN: btoa(response.fileName),
                                mF: btoa("false"),
                                iU: btoa("true"),
                                dO: btoa(localStorage.getItem("directory")),
                                sS: btoa(response.selfSign),
                                dS: btoa(response.documentStatus),
                                ifit: btoa("true"),
                                ifitu: btoa(this._authService.iframeUrl)
                            }
                        });
                        return of(true);
                    } else {
                        // If not valid, redirect to an error page
                        this._router.navigate(["/error"]);
                        return of(false);
                    }
                })
            );
        } else {
            return this._check(redirectUrl);
        }
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === "/sign-out" ? "/" : state.url;
        return this._check(redirectUrl);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check("/");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<boolean> {

        let token = localStorage.getItem("accessToken");

        if (token) {
            let decodedToken: any = jwtDecode(token);

            if (decodedToken.authType && decodedToken.authType === "CLIENT") {
                if (!redirectURL.includes("/documents/prepare")) {
                    if (redirectURL === "/401") {
                        return of(true);
                    } else {
                        this._router.navigate(["/401"], { queryParams: {} })
                        return of(false);
                    }
                }
            }
        }

        return this._authService.check(redirectURL).pipe(
            switchMap(authenticated => {
                // If the user is not authenticated...
                if (!authenticated) {
                    localStorage.setItem(
                        "accessToken",
                        "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjQ4NDY0ODk2LCJleHAiOjE2NDg0ODY0OTZ9.201mCFEoEcnzPqWo5nlPF_T9ZgSntjiSX5LYXtd04Og"
                    );
                    // Redirect to the sign-in page
                    this._router.navigate(["sign-in"], { queryParams: { redirectURL } });

                    // Prevent the access
                    return of(false);
                }

                return of(true);
            })
        );
    }
}
