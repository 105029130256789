import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { appConfig } from './core/config/app.config.dev';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsService {

  private _httpClient = inject(HttpClient);
  private _appConfigService = inject(AppConfigService);

  private basePath = this._appConfigService.getConfigSubject().value;


  private privacyPolicySubject = new BehaviorSubject<string>('');
  privacyPolicy$ = this.privacyPolicySubject.asObservable();

  private termsSettingsSubject = new BehaviorSubject<string>('');
  termsSettings$ = this.termsSettingsSubject.asObservable();

  private logoSubject = new BehaviorSubject<string>('');
  logo$ = this.logoSubject.asObservable();

  private headerBackgroundColorSubject = new BehaviorSubject<string>('');
  headerBackgroundColor$ = this.headerBackgroundColorSubject.asObservable();

  private headerColorSubject = new BehaviorSubject<string>('');
  headerColorSubject$ = this.headerColorSubject.asObservable();

  private leftPanelColorSubject = new BehaviorSubject<string>('');
  leftPanelColorSubject$ = this.leftPanelColorSubject.asObservable();

  private leftPanelIconColorSubject = new BehaviorSubject<string>('');
  leftPanelIconColorSubject$ = this.leftPanelIconColorSubject.asObservable();

  private dialogHeaderColorSubject = new BehaviorSubject<string>('');
  dialogHeaderColorSubject$ = this.dialogHeaderColorSubject.asObservable();

  private dialogColorSubject = new BehaviorSubject<string>('');
  dialogColorSubject$ = this.dialogColorSubject.asObservable();

  private primaryBackgroundColorSubject = new BehaviorSubject<string>('');
  primaryBackgroundColorSubject$ = this.primaryBackgroundColorSubject.asObservable();

  private secondaryBackgroundColorSubject = new BehaviorSubject<string>('');
  secondaryBackgroundColorSubject$ = this.secondaryBackgroundColorSubject.asObservable();

  private primaryColorSubject = new BehaviorSubject<string>('');
  primaryColorSubject$ = this.primaryColorSubject.asObservable();

  private secondaryColorSubject = new BehaviorSubject<string>('');
  secondaryColorSubject$ = this.secondaryColorSubject.asObservable();

  private otherBackgroundColorSubject = new BehaviorSubject<string>('');
  otherBackgroundColorSubject$ = this.otherBackgroundColorSubject.asObservable();

  private otherColorSubject = new BehaviorSubject<string>('');
  otherColorSubject$ = this.otherColorSubject.asObservable();

  private appNameSubject = new BehaviorSubject<string>('');
  appName$ = this.appNameSubject.asObservable();

  getPrivacyPolicySettings(): Observable<any> {
    console.log(this.basePath.basePath);
    

    return this._httpClient.get(this.basePath.basePath + 'auth/v1/policy', { responseType: 'text' as 'json' });
  }

  getTermsSettings(): Observable<any> {

    return this._httpClient.get(this.basePath.basePath + 'auth/v1/terms', { responseType: 'text' as 'json' });
  }

  getBrandingSettings(): Observable<any> {

    return this._httpClient.get(this.basePath.basePath + 'auth/v1/branding');
  }

  getAppName(): Observable<any> {

    return this._httpClient.get(this.basePath.basePath + 'auth/v1/app_name', { responseType: 'text' as 'json' });
  }

  setPrivacyPolicy(policy: string) {
    this.privacyPolicySubject.next(policy);
  }

  setTermsSettings(terms: string) {
    this.termsSettingsSubject.next(terms);
  }

  setLogo(logo: string) {
    this.logoSubject.next(logo);
  }

  setHeaderBackgroundColor(headerBackgroundColor: string) {
    this.headerBackgroundColorSubject.next(headerBackgroundColor);
  }

  setHeaderColor(headerColor: string) {
    this.headerColorSubject.next(headerColor);
  }

  setLeftPanelColor(leftPanelColor: string) {
    this.leftPanelColorSubject.next(leftPanelColor);
  }

  setLeftPanelIconColor(leftPanelIconColor: string) {
    this.leftPanelIconColorSubject.next(leftPanelIconColor);
  }

  setDialogHeaderColor(dialogHeaderColor: string) {
    this.dialogHeaderColorSubject.next(dialogHeaderColor);
  }

  setDialogColor(dialogColor: string) {
    this.dialogColorSubject.next(dialogColor);
  }

  setPrimaryBackgroundColor(primaryBackgroundColor: string) {
    this.primaryBackgroundColorSubject.next(primaryBackgroundColor);
  }

  setSecondaryBackgroundColor(secondaryBackgroundColor: string) {
    this.secondaryBackgroundColorSubject.next(secondaryBackgroundColor);
  }

  setPrimaryColor(primaryColor: string) {
    this.primaryColorSubject.next(primaryColor);
  }

  setSecondaryColor(secondaryColor: string) {
    this.secondaryColorSubject.next(secondaryColor);
  }

  setOtherBackgroundColor(otherBackgroundColor: string) {
    this.otherBackgroundColorSubject.next(otherBackgroundColor);
  }

  setOtherColor(otherColor: string) {
    this.otherColorSubject.next(otherColor);
  }

  setAppName(appName: string) {
    this.appNameSubject.next(appName);
  }

}
