import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { appConfig } from 'app/core/auth/config/app.config.dev';

@Injectable({
    providedIn: 'root',
})
export class CustomSnackBarService {
    // dialogForm: FormGroup;
    constructor(
        public dialog: MatDialog,

        //private dialogRef: MatDialogRef<PrepDocumentComponent>,
        private _translocoService: TranslocoService,
        private _snackBar: MatSnackBar,
    ) {}

    public _showSnackbarOnSuccess(content) {
        let sb = this._snackBar.open(
            this._translocoService.translate(content),
            '',
            {
                duration: 5000,
                panelClass: ['success-snackbar'],
                verticalPosition:
                    appConfig.snackVertPos as MatSnackBarVerticalPosition, // "vertical" // Allowed values are  'top' | 'bottom'
                horizontalPosition:
                    appConfig.snackHoriPos as MatSnackBarHorizontalPosition, // "Horizontal"
                // verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
                // horizontalPosition: "left", // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
            },
        );
    }

    public _showSnackbarOnSuccessWithOutTrans(content) {
        let sb = this._snackBar.open(content, '', {
            duration: 5000,
            panelClass: ['success-snackbar'],
            verticalPosition:
                appConfig.snackVertPos as MatSnackBarVerticalPosition, // "vertical" // Allowed values are  'top' | 'bottom'
            horizontalPosition:
                appConfig.snackHoriPos as MatSnackBarHorizontalPosition, // "Horizontal"
            // verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
            // horizontalPosition: "left", // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
        });
        // sb.onAction().subscribe(() => {
        //   sb.dismiss();
        // });
    }

    public _showSnackbarOnFailure(content) {
        let sb = this._snackBar.open(
            this._translocoService.translate(content),
            '',
            {
                duration: 5000,
                panelClass: ['error-snackbar'],
                verticalPosition:
                    appConfig.snackVertPos as MatSnackBarVerticalPosition, // "vertical" // Allowed values are  'top' | 'bottom'
                horizontalPosition:
                    appConfig.snackHoriPos as MatSnackBarHorizontalPosition, // "Horizontal"
                // verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
                // horizontalPosition: "left", // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
            },
        );
        // sb.onAction().subscribe(() => {
        //   sb.dismiss();
        // });
    }

    public _showSnackbarOnCookiesFailure(content, callback?: () => void) {
        let sb = this._snackBar.open(
            this._translocoService.translate(content),
            '',
            {
                duration: 5000,
                panelClass: ['error-snackbar'],
                verticalPosition:
                    appConfig.snackVertPos as MatSnackBarVerticalPosition,
                horizontalPosition:
                    appConfig.snackHoriPos as MatSnackBarHorizontalPosition,
            },
        );

        sb.afterDismissed().subscribe(() => {
            if (callback) {
                callback();
            }
        });
    }

    public _showSnackbarOnFailureWithOutTrans(content) {
        let sb = this._snackBar.open(content, '', {
            duration: 5000,
            panelClass: ['error-snackbar'],
            verticalPosition:
                appConfig.snackVertPos as MatSnackBarVerticalPosition, // "vertical" // Allowed values are  'top' | 'bottom'
            horizontalPosition:
                appConfig.snackHoriPos as MatSnackBarHorizontalPosition, // "Horizontal"
            // verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
            // horizontalPosition: "left", // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
        });
        // sb.onAction().subscribe(() => {
        //   sb.dismiss();
        // });
    }
}
