import { Layout } from 'app/layout/layout.types';
import { environment } from 'environments/environment';

// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Theme = 'default' | string;

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface appConfigDev {
    layout: Layout;
    scheme: Scheme;
    theme: Theme;
    basePath: String;
    snackVertPos: String;
    snackHoriPos: String;
    apiKey: String;
    socketPath: string;
    pingPath: string;
}

/**
 *
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 */
/*
basePath: 'http://localhost:21587/'
*/
export const appConfig: appConfigDev = {
    layout: 'classy',
    scheme: 'light',
    theme: 'default',
    basePath: environment.basePath, //'http://localhost:7010/',
    socketPath: environment.socketPath, //'http://localhost:7010/ws',
    pingPath: 'https://www.cloudflare.com/cdn-cgi/trace',
    // socketPath: 'https://api.vscrawl.com:9443/ws',
    // basePath: 'https://api.vscrawl.com:9443/',
    snackVertPos: 'bottom',
    snackHoriPos: 'center',

    //zoomApiSecret
    apiKey: '2EfANX7gRQmoLywtQLDjnA',
};
// http://3.67.52.154:8777/
