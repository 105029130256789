import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { appConfig } from 'app/core/config/app.config.dev';
import { Router } from '@angular/router';
import { AppConfigService } from 'app/app-config.service';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    [x: string]: any;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _appConfigService: AppConfigService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    private basePath = this._appConfigService.getConfigSubject();


    /**
     * Setter & getter for user
     *
     * @param value
     */

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    // get(): Observable<User> {
    //   //old api/common/user
    //   return this._httpClient.get<User>(this.basePath.value.basePath + "user/me").pipe(
    //     tap((user) => {
    //       // console.log(this._router.url);

    //       //Check if user is guest
    //       // if (this._router.url != "") {
    //       //   console.log(user);

    //       //   if (user.status == "GUEST") {
    //       //     if (this._router.url.includes("documents/prepare")) {
    //       //       console.log("Accepted");
    //       //     } else {
    //       //       console.log("logout\n============");

    //       //       // localStorage.setItem(
    //       //       //   "accessToken",
    //       //       //   "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjQ4NDY0ODk2LCJleHAiOjE2NDg0ODY0OTZ9.201mCFEoEcnzPqWo5nlPF_T9ZgSntjiSX5LYXtd04Og"
    //       //       // );

    //       //       // Redirect to the sign-in page
    //       //       // window. location. reload();
    //       //       this._user.next(user);
    //       //       this._router.navigateByUrl("/documents");
    //       //     }
    //       //   }
    //       // }

    //       this._user.next(user);
    //     })
    //   );
    // }
    upgradeAccount(): Observable<any> {
        return this._httpClient.post(
            this.basePath.value.basePath + 'v0/organization/upgrade',
            true
        );
    }

    getOrganizationUser(pageSize: number, pageNo: number): Observable<any> {
        return this._httpClient.get(this.basePath.value.basePath + 'organization/v1/users?page=' + pageNo + '&size=' + pageSize + '&sort=createdAt,desc');
    }

    getOrganizationUserWithSearch(pageSize: number, pageNo: number, multiSearchValue: string): Observable<any> {
        return this._httpClient.get(this.basePath.value.basePath + 'organization/v1/users?multiSearchValue=' + multiSearchValue + '&page=' + pageNo + '&size=' + pageSize + '&sort=createdAt,desc');
    }

    getOrganization(): Observable<any> {
        return this._httpClient.get(this.basePath.value.basePath + 'organization/v1');
    }

    sendInvitation(invitation: { name: any, emailAddress: any }): Observable<any> {
        return this._httpClient.post(
            this.basePath.value.basePath + 'organization/v1/user', invitation
        );
    }

    removeUserFromOrganization(memberIds: number[]): Observable<any> {
        const url = `${this.basePath.value.basePath}organization/v1/user`;
        const params = new HttpParams().set('ids', memberIds.join(','));

        return this._httpClient.delete(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        });
    }

    addOrganization(base64: string, name: string): Observable<any> {
        let obj = {
            name: name,
            logo: base64,
        };

        return this._httpClient.post(
            this.basePath.value.basePath + 'v0/organization/add',
            obj
        );
    }

    updateOrganization(id: string, base64: string, name: string): Observable<any> {
        let obj = {
            name: name,
            logo: base64,
        };
        return this._httpClient.put(
            this.basePath.value.basePath + 'organization/v1/' + id, obj
        );
    }

    /**
     * @param formData
     */
    updateSignatureImage(
        fileToUpload: File,
        signatureType: string
    ): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        formData.append('signatureType', signatureType);
        return this._httpClient.post(
            this.basePath.value.basePath + 'v0/user/upload/signature',
            formData
        );
        //old api/Settings/SignatureImage
        //new v0/user/upload/signature
    }

    getSignatureImage(): Observable<any> {
        return this._httpClient.get(
            this.basePath.value.basePath + 'user/v1/settings/signatures'
        );
        //old api/Settings/SignatureImage
        //new v0/user/upload/signature
    }

    getSignerSignatureImage(email: string): Observable<any> {
        return this._httpClient.get(
            this.basePath.value.basePath + 'v0/user/signatureImage/' + email
        );
        //old api/Settings/SignatureImage
        //new v0/user/upload/signature
    }

    /**
     * Update the user
     *
     * @param user
     */
    // update(user: User): Observable<any> {
    //   return this._httpClient.patch<User>("api/common/user", { user }).pipe(
    //     map((response) => {
    //       this._user.next(response);
    //     })
    //   );
    // }

    getIPAddress(): Observable<any> {
        return this._httpClient.get(this.basePath.value.basePath + 'v0/user/ip');
    }

    getIp(): Observable<any> {
        // http://ipv4.myexternalip.com/json
        // https://geolocation-db.com/json/
        return this._httpClient.get<any>(
            'https://www.cloudflare.com/cdn-cgi/trace'
        );
    }


    getBusinessApps(
        clientId: string,
        appName: string,
        description: string,
        callBackUrl: string,
        status: string,
        searchValue: string,
        pageNo: any,
        pageSize: any,
        sort: any,
        order: any
    ): Observable<any> {
        let basePath = this.basePath.value.basePath + "organization/v1/apps?page=" + pageNo + "&size=" + pageSize;
        if (clientId != "") {
            basePath = basePath + "&name=" + clientId;
        }
        if (appName != "") {
            basePath = basePath + "&email=" + appName;
        }
        if (status != "") {
            basePath = basePath + "&status=" + status;
        }
        if (description != "") {
            basePath = basePath + "&roleName=" + description;
        }
        if (callBackUrl != "") {
            basePath = basePath + "&roleName=" + callBackUrl;
        }
        if (sort != "") {
            basePath = basePath + "&sort=" + sort;
        }
        if (order != "") {
            basePath = basePath + "," + order;
        }
        if (searchValue != "") {
            basePath = basePath + "&searchValue=" + searchValue;
        }

        return this._httpClient.get(basePath);
    }

    getAppByClientId(
        clientId: string
    ): Observable<any> {
        return this._httpClient.get(this.basePath.value.basePath + 'organization/v1/apps/' + clientId);
    }

    addBusinessApp(
        clientId: string,
        appName: string,
        appDescription: string,
        callbackUrl: string,
        status: boolean
    ): Observable<any> {
        return this._httpClient.post(this.basePath.value.basePath + 'organization/v1/apps', {
            clientId,
            appName,
            appDescription,
            callbackUrl,
            status
        });
    }

    updateBusinessApp(
        clientId: string,
        appName: string,
        appDescription: string,
        callbackUrl: string,
        status: boolean
    ): Observable<any> {
        return this._httpClient.put(this.basePath.value.basePath + 'organization/v1/apps/' + clientId, {
            appName,
            appDescription,
            callbackUrl,
            status
        });
    }

    deleteBusinessApps(clientIds: string[]): Observable<any> {
        const url = `${this.basePath.value.basePath}organization/v1/apps`;

        // Construct query params
        const params = new HttpParams().set('clientIds', clientIds.join(','));

        return this._httpClient.delete(url, {
            params: params
        });
    }

    generateSecrect(clientId: string): Observable<any> {
        return this._httpClient.get(this.basePath.value.basePath + 'organization/v1/apps/' + clientId + '/secret');
    }

    updateBranding(settingsJson: string): Observable<void> {
        const formData = new FormData();
        formData.append("settingsJson", settingsJson);
        return this._httpClient.put<void>(this.basePath.value.basePath + 'organization/v1/branding', formData);
    }

    resetBranding(): Observable<void> {
        return this._httpClient.get<void>(this.basePath.value.basePath + 'organization/v1/branding-reset');
    }

    getBranding(): Observable<any> {
        return this._httpClient.get(this.basePath.value.basePath + 'organization/v1/branding');
    }
}
